import React, { useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import Heading from "atoms/Heading";
import Text from "atoms/Text";
import Button from "atoms/Button";
import CheckBox from "atoms/CheckBox";
import Image from "next/image";
import OtpComponent from "../molecules/OtpComponent";
import { useFlags } from "flagsmith/react";
import { Controller } from "react-hook-form";

const OtpScreen = ({
  handleLogin,
  loginDetails,
  editMobile,
  resendOtp,
  error,
  control,
  register,
  setValue,
  isAdmin,
}) => {
  const flag = useFlags(["whatsapp_consent", "candidate_login_redesign"]);

  const [otp, setOtp] = useState("");
  //timer logic
  const [timer, setTimer] = useState(30);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );
  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  const resetTimer = function () {
    if (!timer) {
      setTimer(30);
    }
  };

  //events
  const onClickLogin = (event) => {
    event.preventDefault();
    handleLogin(otp);
  };
  const handelResendOtp = () => {
    resetTimer();
    resendOtp();
  };
  return (
    <div
      className={`md:min-h-screen bg-white flex items-center px-6 lg:pl-20 lg:pr-30 ${
        flag.candidate_login_redesign ? "md:py-14 pt-6" : "py-14"
      }`}
    >
      <form onSubmit={onClickLogin}>
        <Heading type="h4" className="mb-2 md:mb-8 font-medium">
          Enter verification code
        </Heading>
        <div className="mb-8 md:mb-9">
          <Text>
            We have just sent verification code to{" "}
            <span className="font-bold">
              {loginDetails.loginType === "email"
                ? `${loginDetails.emailId}`
                : `+${loginDetails.countryCode} ${loginDetails.mobileNumber}`}
            </span>
            <span className="ml-1 mt-1 cursor-pointer">
              <Image
                id="img_tag_150"
                src={
                  flag.candidate_login_redesign.enabled
                    ? "/images/icons/edit-solid-icon.svg"
                    : "/images/icons/edit-icon.svg"
                }
                width="16"
                height="16"
                alt="edit"
                onClick={editMobile}
              />
            </span>
          </Text>
        </div>
        <OtpComponent
          onChange={setOtp}
          otpValue={otp}
          length={4}
          errorMessage={error}
        />

        {timer > 0 && (
          <Text className="my-8 md:my-11 text-sm text-gray-600 font-light">
            Resend OTP in{" "}
            <span className="text-gray-900 font-bold">{timer}s</span>
          </Text>
        )}
        {timer == 0 && (
          <div onClick={handelResendOtp}>
            <Text className="text-primary-900 my-8 md:my-11 cursor-pointer">
              Send the code again
            </Text>
          </div>
        )}
        {isAdmin || !flag.candidate_login_redesign.enabled ? (
          <Button
            variant="primaryBtn"
            fontWeight="font-bold"
            btnClass="w-full"
            disabled={otp.length != 4}
            type="submit"
          >
            Submit
          </Button>
        ) : (
          <Button
            type="submit"
            variant="primaryBtn"
            fontWeight="font-bold"
            btnClass="w-full bg-black disabled:bg-gray-300 disabled:text-white"
            disabled={otp.length != 4}
          >
            Submit
          </Button>
        )}

        {!loginDetails.whatsappConsent &&
          flag.whatsapp_consent.enabled &&
          !isAdmin && (
            <div className="my-5">
              <Controller
                control={control}
                name={`whatsappConsent`}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <CheckBox
                    name="whatsappConsent"
                    checkBoxLabel="Send Notification via Whatsapp"
                    isSelected={value}
                    value={value}
                    onChange={(e) => {
                      setValue("whatsappConsent", e.target.checked);
                    }}
                  />
                )}
              />
            </div>
          )}
      </form>
    </div>
  );
};
export default OtpScreen;

OtpScreen.propTypes = {
  loginDetails: PropTypes.object,
  error: PropTypes.string,
  handleLogin: PropTypes.func,
  editMobile: PropTypes.func,
  resendOtp: PropTypes.func,
};
